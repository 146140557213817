
#Testimonials{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  flex-wrap: wrap;
  color: #033c53;
}
.avatar img{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0 0 6px 8px #3f94b6;
}
.tests{
  display: flex;
  height: max-content;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #034d6a;
}
.avatar{
  background-color: #034d6a;
  width: 100%;
  color: #fff;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.client-review{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.stars{
  color: #ffc251;
}

.stars:first-child{
  margin-left: 10px;
}

/* swipers */

.mySwiper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  background-color: #034d6a;
  padding: 10px;
  width: 100vw;
}