
#About{
  padding: 10px;
  display: flex;
  height: max-content;
  margin-top: 0;
  color: #033c53;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about_container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.about_content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}
.about_cards{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding: 10px;
  width: 100%;
}
.about_card:hover{
  transform: scale(1.045);
  cursor: default;
  transition: var(--transition);
}
.about_card{
  background-color: #096286;
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 5px;
  color: #fff;
  width: 126px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 1rem;
}
.about_me{
  background-color: #096286;
  border-radius: 4rem;
  height:20rem;
  filter: brightness(1.2);
  margin-right: 20px;
  margin-left: 20px;
  width: 16rem;
}
.about_me img{
  border-radius: 4rem;
  height:20rem;
  overflow: hidden;
  transform: rotate(12deg);
  cursor: pointer;
  width: 16rem;
}
.about_me img:hover{
  transform: rotate(0deg);
  transition: All 0.5s linear;
}
.card-box{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 450px;
  padding: 5px;
  width: 90%;
  flex-direction: column;
}
.inner-box{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}
.about_icon{
  color: #f5f5f5;
  font-size: 1.4rem;
}
.about_card h5{
  font-size: 0.9rem;
}
.about_content p{
  color: #010121;
  text-align: justify;
  letter-spacing: 1px;
}

/* crazy buttons */

  .button1{
    background-image: linear-gradient(to right top, #096286, #085d7f, #085878, #075372, #074e6b);
    border: none;
    color: #fff;
    height: 44px;
    width: 150px;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 24px;
    position: relative;
  }
  .button1 a{
    border: none;
    height: 40px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    font-size: 16px;
    z-index: 50;
  }
  .button1::after{
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content:'';
    border-radius: 24px;
    background-image: linear-gradient(to right, #2ba6d7 0%, #2ba6d7 50%, #2ba6d7 100%); 
    transition: all 0.3s ease;
  }
  .button1:hover:after{
    width: 100%;
  }
  .button1 a:hover{
    color: #fff;
  }