
#Services{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 10px;
  color: #033c53;
}
.services-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 14px;
  height: max-content;
  width: 95%;
  padding: 10px;
}
.service{
  background-color: #02415a;
  display: flex;
  width: 350px;
  color: #fff;
  height: 510px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.service:hover{
  transform: scale(1.035);
  cursor: default;
  transition: all 0.3s ease;
}
.service-list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  height: 30px;
}
.service-head1,
.service-head2,
.service-head3{
  background-color: #0e6c6c;
  margin-top: -10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.service-head2{
  margin-top: -1px;
}
.service-head3{
  margin-top: -22px;
}
.SL-icon{
  font-size: 16px;
}