
#portfolio{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: #033c53;
  justify-content: center;
  align-items: center; 
}
.portfolio-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  align-items: center;
}
.port-item-img img{
height: 190px;
width: 250px;
border-radius: 22px;
}

.btn-link{
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  height: max-content;
  justify-content: center;
}
.btn-link .button{
  width: 120px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #f5f5f5 0%, #f9f9f9 50%, #ffff 100%);
}
.btn-link .button a{
  color: #033c53;
}
.btn-link .button a:hover{
  color: #ffff;
}
.btn-link .button::after{
  border-radius: 4px;
  background-color: rgba(0,0,0,0.8);
  background-image: none;
}

.portfolio-item{
  background-color: rgba(0,0,0,0.5);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 300px;
  padding: 15px;
  border-radius: 30px;
  height: 320px;
}
.portfolio-item:hover{
  cursor: default;
  transform: scale(1.035);
  transition: all 0.3s ease-in-out;
}