
footer{
  background-color: #034d6a;
  color: #ffff;
  background-size: cover;
  height: max-content;
  display: flex;
  margin-right:0;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 100px;
  margin-top: 3rem;
}
footer p{
  font-size: 30px;
}
.box{
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  padding-right: 5px;
  width: 90%;
}
footer a:hover{
  color: #ffaac6;
  transform: scale(1.25);
  transition: all 0.25s ease-in-out;
}
.footer-socials{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 30px;
}
.footer-socials a:hover{
  color: #ffff;
}
footer small{
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
footer small > span{
  font-size: 25px;
}

@media screen and (max-width:351px) {
  footer small{
   font-size: 12px;
  }
  footer small >span{
    font-size: 20px;
  }
}