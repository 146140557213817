
#Experience{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: max-content;
  padding: 10px;
}
#Experience h4,
#Experience h2{
  margin: 10px;
  color: #033c53;
}
.exp_container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;  
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  max-width: 850px;
  height: max-content;
  width: 90%;
}
.front , .back{
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  justify-content: space-evenly;
  background-color: #096286;
  height: 400px;
  border-radius: 12px;
  cursor: default;
  width: 350px;
}
.back{
  background-color: transparent;
  border: solid 1px #033c53;
  color: #033c53;
}

.exp_content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 30px;
}
.exp-icon{
  font-size: 18px;
}
.exp_details{
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}
.exp-q{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.exp-q p{
  margin-left: 25px;
}