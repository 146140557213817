header{
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  height: max-content;
  align-items: center;
  justify-content: center;
}
.header-container{
  height: 100%;
  padding: 2rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  position: relative;
}

/*CTA  */
.cta{
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* header socials */
.header_socials{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 20px;
  bottom: 3rem;
}
.header_socials::after{
  content: '';
  width: 2px;
  height: 2.5rem;
  background-image: linear-gradient(to right top, #096286, #085d7f, #085878, #075372, #074e6b);
}
.description{
  display: flex;
  gap: 10px;
  color: #033c53;
  white-space: nowrap;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  width: 50%;
}
.header-container img{
  width: 16rem;
  height: 22rem;
  margin: 14px;
  box-shadow: 0 0 15px 10px rgba(0,0,0,0.3);
  filter: brightness(1.2);
  border-top-left-radius: 8rem;
  border-top-right-radius: 8rem;
}

.scroll_down{
  position: absolute;
  right: 2.3rem;
  bottom: 5rem;
}
.scroll_down i{
  font-size: 25px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  border-radius: 50%;
  background-image: linear-gradient(to right top, #227292, #1d6e8f, #176a8c, #116689, #096286);
  color: #fff;
}

/* media query */
@media screen and (max-width:600px){
  .header_socials, .scroll_down{
    display: none;
  }
}