

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  background-color: var(--color-white);
  color: var(--font-color);
  margin: 0;
  padding: 0;
  line-height: 1.7;
  background-image: url(../public/texture3.avif);
  list-style: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif,Lucida;
  filter: brightness(1.1);
}
:root{
  --font-color: #00000d;
  --color-bg: #0f0f27;
  --color-bg-variant: #2c2c6c;
  --color-primary: #f3f3f3;
  --color-primary-variant: rgba(77,181,255,0.4);
  --color-white: #fff;
  --color-light: rgba(255,255,255,0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms:90%;

  --transition: all 0.3s ease-in-out;
}
html{
  scroll-behavior: smooth;
} 
 ::-webkit-scrollbar{
  display: none;
}

li{
  list-style: none;
}

/* general styles */

section{
  margin-top: 4rem;
}

.text-light{
  color: var(--color-bg-variant);
}
a{
  text-decoration: none;
  color: var(--color-primary);
  transition: var(--transition);
}
a:hover{
  color: var(--font-color);
}
.btn{
  width: max-content;
  display: inline-block;
  color: var(--color-bg);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-bg-variant);
  transition: var(--transition);
}
.btn:hover{
  background-color: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}
.btn-primary{
  background-color: var(--color-primary);
  color: var(--color-bg);
  border: none;
  font-size: 14px;
  font-weight: bold;
}
.btn-primary:hover{
  transform: scale(1.035);
  background-color: #ffb6ce;
  transition: all 0.15s ease-in-out;
}

i{
  color: var(--font-color);
  font-size: 20px;
  font-weight: bold;
}



  /* graphics */
  
@import url(https://fonts.googleapis.com/css?family=Montserrat);
 
 .graphics{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.svg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font: 240px 'Montserrat';
    width: 600px;
    height: 80px;
}
.text-copy {
    fill: transparent;
    stroke: #f5f5f5;
    stroke-dasharray: 6% 29%;
    stroke-width: 12px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 6s infinite linear;
}

.text-copy:nth-child(1){
	stroke: #000045;
	animation-delay: -1;
}

.text-copy:nth-child(2){
	stroke: #123812;
	animation-delay: -2s;
}

.text-copy:nth-child(3){
	stroke: #000;
	animation-delay: -3s;
}

.text-copy:nth-child(4){
	stroke: #000045;
	animation-delay: -4s;
}

.text-copy:nth-child(5){
	stroke: #0b5a79;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
} 



/* my unique buttons */
  .button{
    background-image: linear-gradient(to right top, #096286, #085d7f, #085878, #075372, #074e6b);
    height: 44px;
    width: 150px;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 24px;
    position: relative;
  }
  .button a{
    border: none;
    height: 40px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    font-size: 16px;
    z-index: 50;
  }
  .button::after{
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    content:'';
    border-radius: 24px;
    background-image: linear-gradient(to right, #2ba6d7 0%, #2ba6d7 50%, #2ba6d7 100%); 
    transition: all 0.3s ease;
  }
  .button:hover:after{
    height: 100%;
  }